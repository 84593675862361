import $ from 'jquery';
var teamPhotos;

teamPhotos = {

    init: function () {
        var teamPhotosEl = $('.team-photos');

        if (teamPhotosEl.length > 0) {
            teamPhotos.loadPhotos(function (err, data) {
                if (err) {
                    // error handling
                    console.log('Error accessing instagram', err)
                }
                //console.log(data)
                teamPhotos.renderPhotos(data.data);
            });
        }
    },

    loadPhotos: function (callback) {
        var path     = 'https://api.instagram.com/v1/users/self/media/recent';
        var clientId = 'fe1bdb84891c479f8540077f46c4a3cc';
        var token    = '2023682407.c6a5a8c.c2e707ed161a4cb69760a624725a295d';

        var url = path + '?access_token=' + token + '&client_id=' + clientId + '&callback=?' + '&count=18';

        $.getJSON(url, function (data) {
            // success
            callback(null, data);
        }).fail(function () {
            // failed
            callback(new Error('Could not access instagram'));
        });;
    },

    renderPhotos: function (photos) {
        var output = '';
        // create html string
        $.each(photos, function (p, photo) {
            var imageUrl     = (photo.images && photo.images.low_resolution && photo.images.low_resolution.url) ? photo.images.low_resolution.url : '';
            var imageCaption = (photo.caption && photo.caption.text) ? photo.caption.text : '';

            output += '<div class="column is-one-third-desktop">'
                   +  '    <figure class="image is-square">'
                   +  '        <img src="' + imageUrl + '">'
                   +  '   </figure>'
                   +  '   <div class="caption">' + imageCaption + '</div>'
                   +  '</div>';
        });
        //console.log(output)
        // add to page
        $('.team-photos').html(output);
    }
};

module.exports = teamPhotos;
