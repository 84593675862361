import $ from 'jquery';
import anime from 'animejs';
var all;

all = {
    pathnamne: null,
    init: function () {
        all.initNav();
        all.initContacForm();

        if(this.isHome()) {
          all.animateSVG();
        }
    },

    initNav: function () {
        // navigation toggle
        var navbar     = $('.navbar');
        var menuToggle = $('.navbar-burger');
        var menu       = $('.navbar-menu');
        var body       = $('body');

        this.pathname = window.location.pathname;

        menuToggle.click(function (e) {
            menu.toggleClass('is-active');
            navbar.toggleClass('is-active');
            e.stopPropagation();
        });

        body.click(function () {
            if (menu.hasClass('is-active')) {
                menu.toggleClass('is-active');
                navbar.toggleClass('is-active');
            }
        });
    },

    isHome: function() {
      return (this.pathname == '/' || this.pathname == '/index.html')
    },

    initContacForm: function () {
        var form           = $('#contact .form'),
            thanks         = $('#contact .thanks'),
            contactName    = $('#contact input.name'),
            contactMessage = $('#contact textarea.message'),
            contactEmail   = $('#contact input.email'),
            contactSubmit  = $('#contact .button'),
            inputWrapper   = $('#contact .input-wrapper'),
            url            = '/mail.php';
            inputWrapper.height(inputWrapper.height());

        contactSubmit.click(function () {
            var data = {
                name:    contactName.val(),
                message: contactMessage.val(),
                email:   contactEmail.val()
            }

            $.post(url, data, function () {
              contactSubmit.unbind().addClass('no-pointer').html('Herzlichen Dank für deine Anfrage, wir werden so schnell wie möglich antworten!');
              anime({
                targets: inputWrapper[0],
                duration: 1000,
                height: 0,
                opacity: 0,
                easing: 'easeInOutQuad'
              });
              anime({
                targets: contactSubmit[0],
                backgroundColor: '#59b200',
                borderColor: '#4b9501',
                duration: 500,
                easing: 'easeInOutQuad'
              });
            }, 'json').fail(function (r, error, errormsg) {
                alert('Uups, wie peinlich, da ist leider ein Fehler passiert. Bitte probiere es nochmal oder schreibe uns direkt eine Mail an niklas@digitalwerft.com');
                //form.addClass('is-hidden');
                //thanks.removeClass('is-hidden');
            });
        });
    },

    animateSVG: function() {
      if(window.matchMedia('(max-width: 540px)').matches)
      {
        $('#scene-object').attr('data', 'images/scene-1-mobile.svg');
      } else if (window.matchMedia('(max-width: 900px)').matches)
      {
          $('#scene-object').attr('data', 'images/scene-1-tablet.svg');
      }
      var svgObj = document.getElementById("scene-object");

      svgObj.addEventListener('load', function() {
        // get the inner DOM of alpha.svg
        var svgDoc = svgObj.contentDocument;
        // get the inner element by id

        var waterEl = $(svgDoc).find('#water')[0];
        var skyEl = $(svgDoc).find('#sky')[0];
        var shipEl = $(svgDoc).find('#ship')[0];
        var windowsEl = [
          $(svgDoc).find('#windows-2')[0],
          $(svgDoc).find('#windows-3')[0],
          $(svgDoc).find('#windows')[0]
        ];
        var skylineEl = $(svgDoc).find('#skyline')[0];

        var water = anime({
          targets: waterEl,
          translateX: '-=50',
          direction: "alternate",
          loop: true,
          rotate: '0.01', // Firefox Lag Fix: https://bugzilla.mozilla.org/show_bug.cgi?id=739176
          autoplay: true,
          duration: 4000,
          easing: 'easeInOutQuad'
        });

        var sky = anime({
          targets: skyEl,
          translateY: '-50%',
          loop: false,
          rotate: '0.01', // Firefox Lag Fix: https://bugzilla.mozilla.org/show_bug.cgi?id=739176
          autoplay: true,
          duration: 10000,
          easing: 'easeInOutQuad'
        });

        var ship = anime({
          targets: shipEl,
          translateY: '+=10',
          rotate: '0.01', // Firefox Lag Fix: https://bugzilla.mozilla.org/show_bug.cgi?id=739176
          direction: "alternate",
          loop: true,
          autoplay: true,
          duration: 2000,
          easing: 'easeInOutQuad'
        });

      }, false);
    }
};

module.exports = all;
