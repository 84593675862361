import $ from 'jquery';
import all from './_all';
import home from './_home';
import team from './_team';

$(function () {
    all.init();
    home.init();
    team.init();
});
