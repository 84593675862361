import $ from 'jquery';
var home;

home = {

    init: function () {
        var homeBody = $('body.home');

        if (homeBody.length > 0) {
            homeBody.addClass('loaded');
        }
    }
};

module.exports = home;
